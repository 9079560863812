import styled from "styled-components";

import Epost from ".././components/Epost";

import Blandat from "./../images/artister/2024/blandat.jpg";

import Algotsson from "./../images/artister/2025/mathias_algotsson.jpg";

const ExtraImage = styled.img`
  width: 180px;
  float: left;
  margin: 2px 1rem 0.3rem 0;
`;


const artistInfo = [

  {
    id: 1,
    namn: function () {
      return <>SÄSONGSÖPPNING</>;
    },
    info: function () {
      return (
        <>
          <p>
            Påskafton kl. 11 öppnar krogen dörrarna för säsongen!<br />
            Sedan kommer vi ha öppet lördagar, söndagar och helgdagar kl. 11 - 15.
            <br />Fr.o.m. skolavslutningen 12 juni har vi öppet dagligen; vardagar 11 - 17 och helger 11 - 16.
          </p>
          <p>
            Varmt välkomna till påsk!
          </p>
        </>
      );
    },
    datum: "2025-04-19",
    img: "säsongsöppning",
    pris: 1,
    tid: "11:00",
    kväll: false,
    music: false,
    konst: false,
    author: false,
  },


  {
    id: 2,
    namn: function () {
      return <>MIDSOMMARAFTON</>;
    },
    info: function () {
      return (
        <>
          <p>
            Traditionsenligt så firar vi midsommar på Oxelö Krog med dans och lekar kring midsommarstången till levande musik!<br />

            Midsommarstången kommer att kläs och resas mellan kl 10 - 12.<br />
            Kl. 13 blir det musik och ringlekar!<br />

            <p>Caféet är öppet 10 till 16.
            </p>
            {/* 
            Denna dag har vi en speciell midsommarmeny.
            <ul>
              <li>Midsommartallrik 210 kr - 3 sorters sill, varmrökt lax, räkröra, gräddfil, gräslök, kokt ägg, potatis, smör och bröd.</li>
              <li> Varmrökt lax med färsk potatis och romröra samt smör och bröd</li>
              <li>Räksmörgås</li>
              <li> Pannkakor med grädde och sylt</li>
            </ul>

            <p>Dessutom har vi förstås fika med bland annat vår fina midsommartårta med jordgubbar</p>

          */}
            Varmt välkomna!<br /><br />
            Glöm inte att ta med folkdräkt om du har en!
          </p>
          <p>OBS! På midsommarafton kommer det inte vara möjligt att parkera på Kroggränd eftersom vi kommer ha dansen där. Vi hänvisar istället till stora parkeringen.</p>
        </>
      );
    },
    datum: "2025-06-20",
    img: "midsommar",
    pris: 0,
    tid: "",
    kväll: false,
    music: true,
  },

  {
    id: 3,
    namn: function () {
      return <>Margareta Bengtson</>;
    },
    info: function () {
      return (
        <>
          <p>
            Margareta Bengtson är en av Sveriges mest lysande jazzsångerskor. Hon är en av grundarna av den internationella succén The Real Group och har rest världen över och sjungit med sin fantastiska sopranröst. Klockrent, ekvilibristiskt och musikaliskt! Gruppen lämnade hon 2007 för en solokarriär som fick en flygande start med det kritikerrosade albumet I’m Oldfashioned.
            Under ett framträdande med anledning av Alice Babs 100-årsjubileum, i samband med Kungliga Musikaliska Akademiens årliga högtidssammankomst i måndags tilldelades Alice Babs jazzstipendium.
            Juryns motivering löd: ”Margareta Bengtson har under många år varit den vokalist som mest framgångsrikt och omsorgsfullt skapat musik i Alice Babs anda. Klangen, omfånget, den enastående smidigheten, den stora genrebredden och den höga konstnärliga nivån bidrar alla till att skapa en vokal karaktär som saknar motstycke i det svenska musiklivet.”
            Vi är otroligt glada över att kunna presentera Margareta Bengtson på krogbacken tillsammans med pianisten Mathias Algotsson!
          </p><p>
            <ExtraImage src={Algotsson} />
            Margareta Bengtson & Mathias Algotsson har ett långt samarbete i bagaget. De vill möta publiken i en gränsöverskridande konsert där den traditionella jazzen ligger som grund, men som också innehåller improvisationer över folkliga koraler, visor och egna kompositioner. Hösten 2016 släppte Margareta och Mathias ett album i Japan, ”Ballads”, tillsammans med några av Sveriges mest renommerade jazzmusiker, som fått mycket fina recensioner. Skivan följdes upp av en lång och mycket lyckad turné i Japan november och december samma år. Ytterligare en cd spelades in som släpptes i Sverige under 2017, ”Sorgen och Glädjen”, där Margareta och Mathias improviserar över svenska psalmer, koraler och visor.
          </p><p>
            Margareta Bengtson har en lång karriär som internationellt erkänd sångerska, harpist, kompositör och arrangör. Med stor nyfikenhet fortsätter hon att utforska olika musikaliska världar och rum, med en önskan om att hennes uttryck ska få beröra, trösta, glädja och även visa ett andligt perspektiv på tillvaron. Möten med andra musiker som inspirerar är också en stark drivkraft i hennes konstnärsskap. Margareta växte upp i en musikerfamilj i Tyresö utanför Stockholm. Hon började tidigt sjunga och spela piano och så småningom harpa, som blev hennes huvudinstrument vid Kungliga Musikhögskolan i Stockholm. Den breda publiken kanske mest känner till henne som en av grundarna till a capella ensemblen The Real Group, som rönt stora framgångar över hela världen i flera decennier, och som erhållit många priser och utmärkelser. Bland annat blev Margareta utnämnd till Best Female Vocalist av den amerikanska organisationen CASA 1997. Hon samarbetar numera med Sveriges jazzelit och finaste improvisationsmusiker, såväl som med klassiska kammarmusiker, körer och orkestrar på högsta nivå, och skriver även egen musik. Hösten 2024 fick Margareta ta emot Alice Babs jazz-stipendium som delades ut av Kungl. Musikaliska Akademien.
          </p><p>
            Mathias Algotsson har varit aktiv sedan mitten av nittiotalet och har sedan dess spelat runt om i världen med välkända namn som t.ex. Barbara Hendricks, Magnus Lindgren, Rigmor Gustafsson, Margareta Bengtson (the Real Group) och Svante Henryson. Mathias har en förmåga att förflytta sig mellan olika genrer och stilar och är också en uppskattad kompositör. Han har, förutom till egna grupper, skrivit musik bland annat till Sofia Vokalensemble, Gustaf Sjökvists Kammarkör och S:t Görans Kammarkör. Mathias har gjort sex album i helt eget namn, senast ”Home at Work Again” med egen kvartett. Han har tilldelats ”Dompans Guldsax” som den ende icke-saxofonist som fått Arne Domnerus jazzpris, och också erhållit Sollentuna kommuns kulturpris 2019, stipendier från STIM, SKAP
          </p>
        </>
      );
    },
    datum: "2025-06-28",
    img: "Margareta-Bengtsson",
    foto: "Ewa-Marie Rundquist",
    pris: 230,
    tid: "18:00",
    kväll: true,
    music: true,
    youtube: "https://www.youtube.com/watch?v=XAdF2ys6wkM",
    youtubenamn: "Margareta Bengtson & Mathias Algotsson - teaser",
    youtube2: "https://www.youtube.com/watch?v=pxzmHL_GBqk",
    youtubenamn2: "I'm old fashioned",
    biljett_id: 1208203,
  },

  {
    id: 4,
    namn: function () {
      return <>Peter Asplund och Emmalisa Hallander</>;
    },
    info: function () {
      return (
        <>
          <p>
            Världstrumpetaren Peter Asplund tillsammans det nya unga stjärnskottet; sångerskan Emmalisa Hallander!
          </p><p>
            Efter en över tre decennier lång karriär, med tio album i eget namn, priser och turnéer världen över, har trumpetaren, sångaren och kompositören Peter Asplund, med sitt karaktäristiskt vemodiga och dynamiska trumpetspel, i kombination med sina romantiska kompositioner och sin klassiska swingsångarröst, sin givna plats på jazzens världskarta.
          </p><p>
            Emmalisa Hallander är Sveriges senaste stjärnskott på jazzhimlen. Under senaste året har 24-åriga vokalisten spelat på ett fullsatt Fasching i eget namn, turnerat med Peter Asplund, sjungit som solist med Stockholm Jazz Orchestra, mottagit stipendier från Faschings Vänner, KMA och Sverker och Birgittas Stipendiefond och gjort sig ett namn bland Stockholms jazzelit. Hon har erhållit Alice Babs Talangstipendium och förra året även Monica Zetterlund stipendiet. Hon spelar för närvarande revy på Scalateatern i Stockholm och har ett rykande färskt album i bagaget.
          </p><p>
            Tillsammans med pianisten Leo Lindberg, basisten Christian
            Spering och trumslagaren Johan Löfcrantz Ramsay, tar
            musiken avstamp i The Great American Songbook, men
            blandas med egna kompositioner av Hallander och Asplund.
            Ambitionen är att presentera jazzmusik i formatet
            föreställning, snarare än konsert, där musiken varvas med
            historier, berättelser och anekdoter.

          </p>
        </>
      );
    },
    datum: "2025-07-05",
    img: "Peter-Asplund",
    foto: "",
    pris: 230,
    tid: "18:00",
    kväll: true,
    music: true,
    youtube: "https://www.youtube.com/watch?v=G3-oACKi0Ds",
    youtubenamn: "What we knew",
    youtube2: "https://www.youtube.com/watch?v=CTZvtQHSj9I",
    youtubenamn2: "Miljarder stjärnor",
    biljett_id: 1258155,

  },

  {
    id: 5,
    namn: function () {
      return <>Swing Magnifique</>;
    },
    info: function () {
      return (
        <>
          <p>
            SWING MAGNIFIQUE har länge varit ett av de flitigast anlitade och mest uppskattade banden inom Jazz Manouche/Gypsy Swing, den europeiska jazzstil som främst förknippas med den legendariska belgisk-franska gitarristen Django Reinhardt (1910-1953) och hans grupp Quintette du Hot Club de France, som han bildade i Paris 1934 tillsammans med violinisten Stéphane Grappelli.
            Jazz Manouche är vanligtvis instrumental men det har Swing Magnifique ändrat på: Med en sångerska i bandet består repertoaren delvis av vokala versioner av standards och franska schlagers som till viss del är hämtad från Djangos klassiska inspelningar, men låtar skrivna av Djangos nutida efterföljare samt en och annan egen komposition har också sin givna plats.
          </p><p>
            Allt framförs naturligtvis i Django Reinhardts omisskännliga stil där intensivt sväng och lekfulla, eldiga solon samsas med romantiskt vemod och där den viktigaste ingrediensen är energi. Vår idé är att undvika alltför långa solon utan istället fokusera på bra låtar. Det här är musik som det är svårt att inte beröras av! Våra jazzklubbsframträdanden är alltid mycket uppskattade av både publik och arrangörer.
            För att få det rätta, autentiska soundet har Swing Magnifique den klassiska sättningen för Jazz Manouche, dvs. två akustiska gitarrer, violin och kontrabas.
          </p><p>
            Kvintetten Swing Magnifique består av Josefin Peters (sång), Tom Buhre (gitarr), Filip Magnusson (violin), Åke Jonsson (gitarr), och Filip Berglund (kontrabas).
            Swing Magnifique bildades 2007 och är ett mycket erfaret band inom genren.
          </p><p>
            Välkomna till en afton med swing och elegans!
          </p>
        </>
      );
    },
    datum: "2025-07-12",
    img: "magnifique",
    foto: "",
    pris: 180,
    tid: "18:00",
    kväll: true,
    music: true,
    youtube: "https://www.youtube.com/watch?v=o4o2F0vy7U8",
    youtubenamn: "Cocou",
    youtube2: "https://www.youtube.com/watch?v=MiVmEZtm8yw",
    youtubenamn2: "Sometimes I'm happy",
    biljett_id: 1258162,
  },

  {
    id: 6,
    namn: function () {
      return <>Jojje Wadenius 4-Fusion</>;
    },
    info: function () {
      return (
        <>
          <p>
            Vi är glada och uppspelta över att kunna presentera en konsertkväll 4-Fusion med Georg “Jojje” Wadenius och trion Max Schultz, Leo Lindberg och Chris Montgomery! Kvällen bjuder på ett musikaliskt möte mellan funk och melodisk fusion med några stänk av jazz och blues.
          </p><p>
            Georg ”Jojje” Wadenius – en levande legend inom både svenskt och amerikanskt musikliv. Ingen annan svensk musiker förenar musikalisk briljans och kommersiell framgång som Jojje. Wadenius var under 80-talet en av de mest anlitade gitarristerna i New York och medverkande på skivinspelningar med bland annat Diana Ross, Aretha Franklin, Luther Vandross och Steely Dan. Han har även medverkat på turnéer med artister som James Brown, Kent och Simon & Garfunkel och spelat i husbandet till det amerikanska TV-programmet Saturday Night Live. I mitten av 90-talet återvände Wadenius till Skandinavien och sen dess har han spelat i egna band och spelat in egna skivor, producerat album åt andra artister, arbetat som solist med olika storband och undervisat. Han har även samarbetat med en rad stora artister i Sverige Pugh Rogefeldt, Siw Malmqvist och Cornelis Vreeswijk.
          </p><p>
            Den legendariska jazzgitarristen Max Schultz spelar och komponerar musik inom såväl jazz-, blues- och popgenren. Han började spela gitarr vid 10 års ålder efter att ha sett en konsert med Jimi Hendrix i Stockholm. Han har turnerat med bandet Rena vätskor och engagerat sig i gruppen Mynta, där han under sju år utvecklades som jazzgitarrist och även kom i kontakt med indiska musikaliska influenser. Mynta turnerade såväl i Skandinavien som på internationella arenor som Montreux Jazz Festival samt i Singapore, Bombay, Kuba och Nicaragua. 1993 vann Schultz Rikskonserters och Caprices utmärkelse Jazz i Sverige. Han har samarbetat med många jazzmusiker och artister, som Håkan Broström, Herbie Hancock, Bob Moses, Nils Landgren, Esbjörn Svensson, Svante Thuresson, Anna Nederdal och Tommy Körberg. Schultz har även arbetat som studiomusiker på inspelningar med Marie Fredriksson, Rebecka Törnqvist och andra artister.
          </p><p>
            Den hyllade pianisten Leo Lindberg har tilldelats flera priser, däribland Louis Armstrong-, Monica Zetterlunds- och Faschings Vänners stipendium, samt solistpriset i tävlingen Nordic Jazz Comets. Med influenser hämtade från bland annat Bud Powell och Wynton Kelly äger Lindberg en lysande konstnärlig fantasi som får musiken att slå ut i full blom, samt besitter en imponerande teknik med tonkaskader som är en fröjd att lyssna till. Sedan debutalbumet Leos Bag (2014) har flera andra skivor utkommit, såväl egna som i samverkan med andra profilerade musiker som sångerskan Rigmor Gustafsson och gitarristen Max Schultz.
          </p><p>
            Chris Montgomerys rykte som en dynamisk, flyhänt och samtidigt sofistikerad och sammanhållande trummis föregår honom och dessa egenskaper gör honom till ett givet val för många jazz och bluesmusiker och artister att samverka med i olika konstellationer. Chris har anlitats av och spelat med många etablerade profiler inom svensk jazz och blues/rock, bland annat Rigmor Gustafsson, Svante Thuresson, Max Schultz, Barbara Hendricks, Patrik Boman, Erik Söderlind, Leo Lindberg, Isabella Lundgren och Hans Backenroth.

          </p><p>
            Lineup:<br />
            Georg ”Jojje” Wadenius - gitarr, sång<br />
            Max Schultz - gitarr<br />
            Leo Lindberg - keyboards<br />
            Chris Montgomery - trummor
          </p>
        </>
      );
    },
    datum: "2025-07-19",
    img: "Jojje",
    foto: "",
    pris: 280,
    tid: "18:00",
    kväll: true,
    music: true,
    youtube: "https://www.youtube.com/watch?v=AIQlxsEB4AE",
    youtubenamn: "Live 2023",
    biljett_id: 1258158,
  },

  {
    id: 7,
    namn: function () {
      return <>The Blue Benders</>;
    },
    info: function () {
      return (
        <>
          <p>
            Denna konsert ingår i våra BLUESDAGAR 30 juli - 2 augusti. Under bluesdagarna kommer vi även ha blues dagtid med fri entré för de som gästar caféet. Biljett kan köpas separat till denna konsert men man kan även köpa ett BLUESPASS som ger entré till alla fyra blueskonserter till rabatterat pris.
          </p>
          <p>
            På drygt två år har The Blue Benders etablerat sig som en kraft att räkna med inom svensk blues/roots/soul/funk/rock. Inte bara som det nya unga blueshoppet, utan som en fulländad musikmaskin med ett sound och sväng som imponerar på det mest erfarna rock- och bluesrävarna. Ett band som dock inte vill låta dig definieras som enbart blues utan hämtar sina influenser från alla håll för att bilda en musikalisk cocktail från alla världar, där tunga riff möts av funkiga basgångar och hela tiden inspirerat av den afroamerikanska musiken
          </p>
          <p>
            Fronttrion, tillsammans med det tunga, ångvältande kompet är häpnadsväckande, eller ”onödigt bra”, som Roffe Wikström uttryckte det efter att de delat kväll med honom i Göteborg i december-21.
          </p>
          <p>
            Bandet, som tilldelades utmärkelsen "Årets Nykomling" i Bluespoddens omröstning 2022, har inte bara imponerat som kollektiv, utan även individuellt. Edvin Öström har två år i rad utsetts till "Årets Gitarrist", och sångaren och entertainergeniet Samson Mirro för den legendariska familjetraditionen vidare från farfar Eric Bibb och pappa Rennie Mirro. Morris Malek sanslösa slidegitarr spottar, frustar och fräser. Johan Sund (Lisa Lystam Family Band, the Bland, m fl), basisten och världsvirtuosen, är sedan hösten -22 ny basist i bandet. Trummisen och multigeniet Oskar Boså langar dynamik, sväng och stadga med sällsynt pondus, bidrar till bandets karakteristiska sound.
          </p>
          <p>
            I mitten av juli 2023 gick bandet in i Immersive music studios (gamla EMI studion) för att spela in nytt material. Resultatet blev EP:n "Strike One", som släpptes den 16e november 2023. Den efterlängtade EP:n bjuder på en ny dimension av bandets musikaliska talang och har fått enorm positiv respons från deras dedikerade fanbase. Med en utsåld releasekonsert på Nalen Klubb, 300 pers, i bagaget är bandet minst sagt inne i en spännande period och ser de fram emot att fortsätta sin resa under 2024.
          </p>
          <p>
            De gör det så bra att de tog hem vinsten i den svenska tävlingen av European Blues Challenge och drar till Portugal i april 2024 för att representera Sverige i bluesen egen Eurovision.
          </p>
          <p>
            Blue Benders live är en hyllad upplevelse och ett unikt samspel med publiken. Det är kvällar som aldrig vill ta slut.  Energi och spelglädje eller som trumlegendaren Ingemar Dunker (RIP) uttryckte det efter att han upplevt bandet live på Bangens Jazzfestival 2023, ”Det bästa jag hört på mycket mycket länge”
          </p>
          <p>
            Bandet har sitt ursprung ur Stockholms jam-scen i Gamla Stan. Edvin drog med sig Morris till jammen redan under första året på musikgymnasiet, Rytmus. Stampen, St Clara Wirströms, Engelen, etc blev deras musikaliska vattenhål under gymnasieåren. Samson kom snart in i bilden och det jammades i parti och minut. Tommy Moberg, den gamle legendaren, såg potentialen och satte ihop ett band omkring grabbarna, som hade sin första officiella spelning under namnet The Blue Benders i maj 2021 i samband med Old Town Blues Stream.
          </p>
        </>
      );
    },
    datum: "2025-07-30",
    img: "benders",
    pris: 200,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "",
    slutsålt: false,
    biljett_id: 1258156,
    bluespass: 1264409,
  },

  {
    id: 8,
    namn: function () {
      return <>Knock-Out Greg and the Blue Flames</>;
    },
    info: function () {
      return (
        <>
          <p>
            Denna konsert ingår i våra BLUESDAGAR 30 juli - 2 augusti. Under bluesdagarna kommer vi även ha blues dagtid med fri entré för de som gästar caféet. Biljett kan köpas separat till denna konsert men man kan även köpa ett BLUESPASS som ger entré till alla fyra blueskonserter till rabatterat pris.
          </p>
          <p>
            Greger ”Knock-out Greg” Andersson startade sin musikkarriär med den numera smått legendariska orkester K-O Greg & Blue Weather som turnerade hårt i närmare 20 år och släppte 7 album. 50 fyllda och med ett lastbilslass rutin i ryggen samlar han nu ihop några av Skandinaviens bästa musiker i blues/soul-genren. Vi kommer att få höra mycket blues men även en del oldschool soul.
          </p><p>
            I bandet återfinns som alltid Gregers yngre bror Marcus på trummor. Bröderna har spelat ihop i över 30 år sen starten i Gnesta 1988 och avverkat tusentals gig tillsammans. Basen hanteras av Lars Näsman känd bla som basist i Trickbag sen många år och en mängd andra sammanhang. På keyboard och sax återfinns Torbjörn Eliasson även han mångårig medlem av K-O Greg & Blue Weather. Tobbe spelade piano i Sven Zetterbergs band länge och blåser numera också sax i kultbandet The Beat from Palookaville.
          </p>
        </>
      );
    },
    datum: "2025-07-31",
    img: "knock-out-greg",
    foto: "",
    pris: 180,
    tid: "18:00",
    kväll: true,
    music: true,
    youtube: "https://www.youtube.com/watch?v=eAn73gl7r5Q",
    youtubenamn: "Further Information",
    biljett_id: 1258159,
    bluespass: 1264409,
  },

  {
    id: 9,
    namn: function () {
      return <>Ladies got the Blues</>;
    },
    info: function () {
      return (
        <>
          <p>
            Denna konsert ingår i våra BLUESDAGAR 30 juli - 2 augusti. Under bluesdagarna kommer vi även ha blues dagtid med fri entré för de som gästar caféet. Biljett kan köpas separat till denna konsert men man kan även köpa ett BLUESPASS som ger entré till alla fyra blueskonserter till rabatterat pris.
          </p>
          <p>
            Ladies Got the Blues är ett musikerkollektiv och flermannaband bestående av Sveriges samlade unga (kvinnliga) blueselit som turnerat i Sverige och Europa sedan 2014. Bandet uppträder i olika konstellationer och är en hyllning till de kvinnliga musiker som starkt bidragit till genrens utveckling genom historien.
          </p>
          <p>
            Under 2023 har Ladies Got the Blues fokus på omtolkningar av kvinnors musik både genom historien och samtida artister inom blues och närliggande genrer. Både riktiga bluesklassiker och några mer otippade låtar står på menyn! Till krogbacken kommer en konstellation av sju musiker.
          </p>
          <p>
            Sen 2014 har bandet turnerat runt om i Sverige och Europa med sin show. Följ med på en resa från 1920-talets Mississippi till 2000-talets svenska scener där du bland annat hör musik av:
          </p>
          <p>
            – Sister Rosetta Tharpe (1915–1973) Gitarrist, kompositör och sångerska som har inspirerat artister som Aretha Franklin, Chuck Berry, Elvis Presley och TinaTurner.
          </p>
          <p>
            – Memphis Minnie (1897–1973) Kompositör, gitarrist och sångerska blev invald i Blues Foundations Hall of Fame 1980. Hon spelade in ungefär 200 sånger, låtar som sedan har tolkats och spelats in av bland andra Jefferson Airplane och Led Zeppelin.
          </p>
          <p>
            – Big Mama Thornton (1926–1984) Sångerska och kompositör som var den första att spela in låten Hound Dog som blev en stor hit på Billboards lista 1953.
          </p>
        </>
      );
    },
    datum: "2025-08-01",
    img: "ladies",
    pris: 230,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "",
    slutsålt: false,
    youtube: "https://www.youtube.com/watch?v=krpQq2txu3c",
    youtubenamn: "I'm outta here",
    youtube2: "https://www.youtube.com/watch?v=FO3KrhGf2do",
    youtubenamn2: "Gun In My Hand",
    biljett_id: 1258160,
    bluespass: 1264409,
  },

  {
    id: 10,
    namn: function () {
      return <>Eric Bibb</>;
    },
    info: function () {
      return (
        <>
          <p>
            Denna konsert ingår i våra BLUESDAGAR 30 juli - 2 augusti. Under bluesdagarna kommer vi även ha blues dagtid med fri entré för de som gästar caféet. Biljett kan köpas separat till denna konsert men man kan även köpa ett BLUESPASS som ger entré till alla fyra blueskonserter till rabatterat pris.
          </p>
          <p>
            Eric Bibb, född och uppvuxen i New York, är med sina över fyrtio album och mer än femtio år på scenen, en av den internationella bluesvärldens största namn idag och hyllningarna som en av världens främsta akustiska bluesgitarrister är ständigt återkommande.
          </p><p>
            Han har turnerat världen runt i snart trettio år, blivit Grammy-nominerad i USA tre gånger och fått mängder av andra internationella utmärkelser, som t. ex pris för årets akustiska bluesalbum från The Blues Foundation i Memphis 2017 & 2022 och ”Blues Act Of The Year” vid Jazz FM Awards i London 2019. 2022 fick Eric även Stockholms Stads hederspris i musik.
          </p><p>
            Erics far, artisten Leon Bibb, kämpade jämsides med Martin Luther King, Harry Belafonte, Joan Baez och många fler för svartas rättigheter i USA på sextiotalet. Leons framtidsvisioner och mål-medvetenhet tog sig uttryck i musiken och formade Erics konstnärsskap från tidig ålder. Hemma hos familjen Bibb rörde sig en konstant ström av erkända musiker och artister som John Lewis (Erics morbror - pianist/kompositör, grundare av Modern Jazz Quartet), Paul Robeson, Pete Seeger och Josh White. Eric minns särskilt tydligt hur han vid elva års ålder står i pyjamas i vardagsrummet och berättar för Bob Dylan att han minsann också spelar gitarr…
          </p><p>
            Eric har samarbetat med storheter som t.ex Mavis & Pops Staples, Taj Mahal, Odetta, Bonnie Raitt, The Blind Boys of Alabama och The Dixie Hummingbirds. 2019 spelade han för utsålda hus i en vecka på legendariska Ronnie Scott’s i London, då även Van Morrison var Eric’s gäst en av kvällarna.
          </p><p>
            2017 turnerade han Sverige runt med den hyllade enmans-föreställningen ”Tales From A Blues Brother”, som också sändes i Sveriges Television. Den producerades av hans son Rennie Mirro och Filip Adamo och kan nu ses på Qwest TV. Ni har också kunnat se honom i På Spåret, SVT och Bingolotto, TV4, där han sjöng en duett med Sarah Dawn Finer. Sommaren 2022 såg ni honom i SVT:s Allsång På Skansen!
          </p><p>
            2021 släppte han plattan ”Dear America” med gäster som bl. a Ron Carter, Eric Gales och Steve Jordan. 2023 släpptes hans opus Ridin’, den liksom Dear America producerades av Glen Scott, nu med gäster som Taj Mahal och Russell Malone.
          </p><p>
            I september 2022 kunde du se honom på Scalateaterns scen Stockholm tillsammans med ett spännande band och gästerna Sarah Dawn Finer och Rennie Mirro. Konserten spelades in och filmades inför ett live-album, som släpptes i april 2024. Hösten 2023 medverkade han i föreställningen ’Ögonblicket’ på Västmanlands Teater och är just nu aktuell i rollen som den demente musikern John i filmen ’Så Länge Hjärtat Slår’, som kan ses på TV4 Play.
          </p>
        </>
      );
    },
    datum: "2025-08-02",
    img: "Eric-Bibb",
    pris: 280,
    tid: "18:00",
    kväll: true,
    music: true,
    foto: "Michael Wall",
    slutsålt: false,
    youtube: "https://youtu.be/HYhwUgl3P0U?si=Rcf3quZItAXlTj_i",
    youtubenamn: "Family",
    youtube2: "https://youtu.be/j__moFFnAJg?si=ek6RKqQSL1YU0HyU",
    youtubenamn2: "500 miles",
    biljett_id: 1258157,
    bluespass: 1264409,
  },

  {
    id: 12,
    namn: function () {
        return <>Herman Lindqvist</>;
    },
    info: function () {
        return (
            <>
                <p>
                    Vi är oerhört glada att det har blivit en tradition att Herman Lindqvist kommer till oss! Vi hälsar honom hjärtligt välkommen tillbaka till Krogbacken!<br /><br />
                    Denna gång kommer Herman berätta om sin nya bok "När Sverige var i gungning - Frihetstiden i skuggan av det ryska hotet" om ett land i inrikespolitiskt kaos som var mycket nära att bli en rysk vasallstat.
                </p>
                <p>Ingen kan om Herman berätta vittert och underhållande direkt ur minnet! Missa inte det!</p>
                <p>
                Herman Lindqvist har skrivit över sextio böcker och han skriver ständigt fler. Det är framför allt historiska verk, men också skönlitteratur, biografier, kåserier m.m.
</p><p>
Han föddes i Stockholm 1943, men växte upp i Helsingfors. Han tog studenten, läste statskunskap och började sitt yrkesliv som journalist. Vid 25 års ålder blev han utrikeskorrespondent för Aftonbladet och bevakade sedan under mer än tjugo år krig och katastrofer i alla världsdelar för Aftonbladet, Expressen, SVT och Riksradion. Numera är han bosatt i Warszawa.
</p><p>
År 1969 debuterade han med en reportagebok om den ryska invasionen av Tjeckoslovakien. I sin skönlitterära debut Strax före deadline (1980) skildrade han sina upplevelser då de röda khmererna tog över Pnom Penh i Kambodja.
</p><p>
Sedan 1990 är han heltidsförfattare. Under åren 1992–2006 utgavs hans stora verk Historien om Sverige i elva delar. Den bokserien har tillsammans med de omkring trettio avsnitten av Hermans historia som sändes i SVT mellan 1991 och 2002 starkt bidragit till det ökade historieintresset i Sverige. Många har vittnat om att det är Herman Lindqvists medryckande sätt att berätta som har fått dem intresserade av historia.
</p><p>
Herman Lindqvist har en djup kunskap om Sveriges kungahus, som han har skrivit flera böcker om, senast Kungligt och mänskligt (2010). Han har också undervisat prinsessorna Victoria och Madeleine i Sveriges historia.
</p><p>
Hans serie om franska historiska personligheter började 2008 med Madame de Pompadour, ett porträtt av 1700-talsborgarflickan som blev kunglig älskarinna och fick en maktställning som få andra. Året därpå kom Jean Bernadotte, mannen vi valde, om den franske marskalken som blev vår förste kung av ätten Bernadotte, Karl XIV Johan, och våren 2011 kom Ludvig XIV, Solkungen.
</p><p>
Herman Lindqvists självbiografi om livet som journalist och författare, Mitt i allt, kom 2012. Året därpå skrev han en bok han länge velat skriva, om Sveriges och Finlands gemensamma historia: När Finland var Sverige. För den belönades han med 18 000 euro av Svenska Litteratursällskapet i Finland och med ett hedersdoktorat vid Åbo Akademi. Därefter har den flitige författaren också skrivit om Erik XIV och om Sverige som kolonialmakt, något som ingen tagit ett samlat grepp om tidigare.
                </p>
                <p>
                    Varmt välkomna!
                </p>
            </>
        );
    },
    datum: "2025-06-24",
    img: "Herman-Lindqvist",
    pris: 0,
    tid: "14:00",
    kväll: false,
    music: false,
},

];

export default artistInfo;